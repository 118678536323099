<template>
	<edit-template @confirm="resetForm" @cancel="onBack" :cancelRemind="false" confirmText="保存" cancelText="取消">

    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="demo-ruleForm form-body" label-width="140rem" style="padding-top: 70rem;padding-left: 48rem">
      <el-form-item label="套餐名称" prop="pack_name">
        <el-input v-model="ruleForm.pack_name" class="independentStyle" placeholder="请输入套餐名称"></el-input>
      </el-form-item>
      <el-form-item label="套餐类型" prop="pack_type">
        <el-select v-model="ruleForm.pack_type">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否支持线下支付" prop="offline">
        <el-select v-model="ruleForm.offline">
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="编辑商品组合" prop="name">
        <el-button @click="onAdd">点击添加</el-button>
      </el-form-item>
      <div v-if="data.pack_group_list" style="display: flex;flex-wrap: wrap;justify-content: left;margin-left: 120rem">
        <div v-for="(item,index_) in data.pack_group_list" style="border: 1rem solid #e8edff;min-height: 120rem;width: 400rem;margin: 12rem">
          <div v-if="data.pack_group_list[index_]" style="padding: 12rem 24rem;font-size: 14rem;color: #666">
            <div style="margin-bottom: 12rem;font-size: 14rem;color: #666">
              <span style="margin-right: 12rem;">选择规格:</span>
              <span style="color: #000">{{ data.pack_group_list[index_].goods_standard_name }}</span>
            </div>
            <div style="margin-bottom: 12rem">
              <span style="margin-right: 12rem">预订商品价格:</span>
              <span style="color: #000">{{ numArrTo[index_] ? numArrTo[index_] : 0 }}</span>
            </div>
            <div style="margin-right: 12rem;margin-bottom: 12rem">
              <span style="margin-right: 12rem;">优惠金额:</span>
              <span style="color: #000">{{ numArr[index_] ? numArr[index_] : 0 }}</span>
            </div>
            <div style="margin-right: 12rem;margin-bottom: 12rem">
              <span style="margin-right: 12rem;">商品数量(含预订商品):</span>
              <span style="color: #000">{{ data.pack_group_list[index_].goods_list.length }}</span>
            </div>
            <div style="margin-right: 12rem;margin-bottom: 12rem">
              <span style="margin-right: 12rem;">商品总价:</span>
              <span style="color: #000">{{ priceArr[index_] - numArr[index_] ? (priceArr[index_] - numArr[index_]).toFixed(2) : 0 }}</span>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;padding: 12rem 24rem;background: #f7f7f7;margin-top: 12rem">
            <el-button style="width: 30%" @click="onEdit(index_)">编辑</el-button>
            <el-button style="width: 30%" @click="onUnder(index_)">{{ item.is_online == 1 ? '下架' : '上架' }}</el-button>
          </div>
        </div>
      </div>
    </el-form>



    <el-dialog v-if="ruleForm.pack_group_list.length" :before-close="onTakeAway" :visible.sync="dialogVisible" title="编辑组合商品" width="1000">
      <div style="padding: 0 12rem;display: flex;justify-content: space-between">
        <div>
          <span>选择规格</span>
          <el-select v-model="ruleForm.pack_group_list[ruleForm.pack_group_list.length-1].goods_standard_id" :popper-append-to-body="false" style="margin-left: 24rem" @change="onchangeGoodsStandardId">
            <el-option v-for="item in productSpecificationsArr" :key="item.id" :label="item.standard_name" :value="item.id"></el-option>
          </el-select>
        </div>
        <span>
          优惠金额: {{ numArr[index] ? numArr[index] : 0 }}
        </span>
      </div>
      <div style="margin-top: 12rem">
        <h3>选择商品组合(除预订商品)</h3>
        <div v-for="(item,index) in ruleForm.pack_group_list[ruleForm.pack_group_list.length-1].goods_list" style="background: #f2f2f2;padding: 12rem 12rem;margin-top: 12rem">
          <div style="display: flex;justify-content: space-between">
            <div style="display: flex;justify-content: space-between;align-items: center">
              <div>
                <span>{{ ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list[index].is_prepaid === 1 ? '预订商品' : '选择商品' }}</span>
                <el-select v-model="ruleForm.pack_group_list[ruleForm.pack_group_list.length-1].goods_list[index].goods_id" :popper-append-to-body="false" style="margin-left: 24rem" @change="onGoods_standard_id(index)" :disabled="ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list[index].is_prepaid === 1">
                  <el-option v-for="(item,index) in bookingGoodsArr" :label="item.goods_name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div style="margin-left: 24rem">商品价格:{{ ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list[index].goods_price ? ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list[index].goods_price : '0' }}</div>
            </div>
            <div>
              <el-button v-if="index === ruleForm.pack_group_list[ruleForm.pack_group_list.length-1].goods_list.length-1" @click="onAddGoodsStandardId">添加</el-button>
              <el-button v-show="ruleForm.pack_group_list[ruleForm.pack_group_list.length-1].goods_list.length > 1" :disabled="ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list[index].is_prepaid === 1" @click="onDeleteGoodsStandardId(index)">删除</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <template>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-button @click="onTakeAway">取 消</el-button>
              <el-button type="primary" @click="onSure" :disabled="!countRuleForm">确 定</el-button>
            </div>
            <div style="display: flex;align-items: center">
              <span style="margin-right: 12rem">商品数量(含预订商品):</span><span style="margin-right: 24rem">{{ ruleForm.pack_group_list[ruleForm.pack_group_list.length - 1].goods_list.length }}</span>
              <span style="margin-right: 12rem">用户实付金额:</span><span>{{ total - numArr[index] ? (total - numArr[index]).toFixed(2) : 0 }}</span>
            </div>
            </div>
        </template>
      </span>
    </el-dialog>

    <el-dialog :before-close="()=> dialogVisibleTo = false" :visible.sync="dialogVisibleTo" title="编辑组合商品" width="1000">
      <div style="padding: 0 12rem;display: flex;justify-content: space-between">
        <div>
          <span>选择规格</span>
          <el-select v-model="dataTo.goods_standard_id" :popper-append-to-body="false" style="margin-left: 24rem" @change="onchangeGoodsStandardIdTo">
            <el-option v-for="item in productSpecificationsArr" :key="item.id" :label="item.standard_name" :value="item.id"></el-option>
          </el-select>
        </div>
        <span>
          优惠金额: {{ abatePriceTo ? abatePriceTo : 0 }}
        </span>
      </div>
      <div style="margin-top: 12rem">
        <h3>选择商品组合(除预订商品)</h3>
        <div v-for="(item,index) in dataTo.goods_list" style="background: #f2f2f2;padding: 12rem 12rem;margin-top: 12rem">
          <div style="display: flex;justify-content: space-between">
            <div style="display: flex;justify-content: space-between;align-items: center">
              <div>
                <span>{{ item.is_prepaid === 1 ? '预订商品' : '选择商品' }}</span>
                <el-select v-model="dataTo.goods_list[index].goods_id " :popper-append-to-body="false" style="margin-left: 24rem" @change="onGoods_standard_idTo(index)" :disabled="item.is_prepaid === 1">
                  <el-option v-for="(item,index) in bookingGoodsArr" :label="item.goods_name" :value="item.id"></el-option>
                </el-select>
              </div>
              <div style="margin-left: 24rem">商品价格:{{ item.goods_price ? item.goods_price : 0 }}</div>
            </div>
            <div>
              <el-button v-if="index === dataTo.goods_list.length-1" @click="onAddGoodsStandardIdTo">添加</el-button>
              <el-button v-if="dataTo.goods_list.length>1" :disabled="item.is_prepaid === 1" @click="onDeleteGoodsStandardIdTo(index)">删除</el-button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <template>
          <div style="display: flex;justify-content: space-between">
            <div>
              <el-button @click="dialogVisibleTo = false">取 消</el-button>
              <el-button type="primary" @click="onSureTo" :disabled="!countRuleFormTo">确 定</el-button>
            </div>
            <div v-if="dataTo.goods_list" style="display: flex;align-items: center">
              <span style="margin-right: 12rem">商品数量(含预订商品):</span><span style="margin-right: 24rem">{{ dataTo.goods_list.length }}</span>
              <span style="margin-right: 12rem">用户实付金额:</span><span>{{ totalTo - abatePriceTo ? (totalTo - abatePriceTo).toFixed(2) : 0 }}</span>
            </div>
            </div>
        </template>
      </span>
    </el-dialog>

</edit-template>
</template>

<script>

export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  name: 'add',
  data() {
    return {
      dataTo: {},
      abatePriceTo: 0,
      totalTo: 0,
      editIndex: 0,
      ruleForm: {
        pack_name: '', // 套餐名称
        pack_type: '',
        offline: '',
        pack_group_list: [],
      },
      data: {},
      rules: {
        pack_name: [
          {required: true, message: '请输入套餐名称', trigger: 'change'},
          {min: 1, max: 30, message: '请输入1至30个字符', trigger: 'change'},
        ],
        pack_type: [
          {required: true, message: '请选择套餐类型', trigger: 'change'},
        ],
        offline: [
          {required: true, message: '请选择是否线下支付', trigger: 'change'},
        ]
      },
      dialogVisible: false,
      dialogVisibleTo: false,
      productSpecificationsArr: [], // 选择规格
      bookingGoodsArr: [], // 预订商品
      total: 0,
      num: 0,
      numArr: [],
      index: -1,
      priceArr: [],
      numArrTo: [],
      editData: {},
      options: [
        { value: 1, label: '1元预报名' },
        { value: 2, label: '千元预报名' },
        { value: 3, label: '全额预报名' },
        { value: 4, label: '正式报名' },
        { value: 5, label: '高二高三续费' },
        { value: 6, label: '复读生正式报名' },
        { value: 7, label: '保过订单' },
        { value: 8, label: '生活费' }
      ]
    };
  },

  watch: {
    ruleForm: {
      // 数据变化时执行的逻辑代码
      handler(newName, oldName) {
        this.num = 0;
        this.total = 0;
        if (oldName.pack_group_list.length) {
          oldName.pack_group_list[oldName.pack_group_list.length - 1].goods_list.forEach(item => {
            this.total += item.goods_price;
            if (item.goods_id) {
              this.num++;
            }
          });
        }
      },
      // 开启深度监听
      deep: true,
    },

    dataTo: {
      // 数据变化时执行的逻辑代码
      handler(newName, oldName) {
        this.num = 0
        this.totalTo = 0
        if (newName.goods_list.length) {
          setTimeout(() => {
            newName.goods_list.forEach(item => {
              if (item.goods_price) {
                this.totalTo += Number(item.goods_price)
              } else this.totalTo += 0
            })
          }, 100)
        }
      },
      // 开启深度监听
      deep: true
    },
  },

// 方法集合
  methods: {
    onBack() {
      this.$confirm('是否取消新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$router.back();
      });
    },
    resetForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$_register.post('api/recruit/goods-manage/add-goods-pack', {goods_packs: JSON.stringify(this.ruleForm)}).then(res => {
            if (res.data.status === 0) {
              this.$message.success('新增成功');
              this.$store.commit('setPage', 1)
              this.$router.back();
            }
          });
        }
      });
    }
    ,

    // 选择规格
    onchangeGoodsStandardId(val) {
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list = [{
        goods_id: '',
        goods_name: '',
        goods_price: '',
        is_prepaid: '',
      }];
      this.$_register.get('api/recruit/goods-manage/get-book-goods', {params: {goods_standard_id: val}}).then(res => {
        if (res.data.data.length === 0) this.$message.warning('该规格下没有预订订单');
        let data = this.productSpecificationsArr.filter((item) => {
          return item.id === val;
        });
        this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_standard_name = data[0].standard_name;
        let dataTo = this.productSpecificationsArr.filter((item) => {
          return item.id === val;
        });
        this.numArr[this.index] = (dataTo[0].abate_price);
        res.data.data.forEach(item => {
          this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[0] = {
            goods_id: item.id,
            goods_name: item.goods_name,
            goods_price: item.goods_price,
            is_prepaid: 1,
          };
        });
        if (this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[0].goods_price) {
          this.numArrTo.push(this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[0].goods_price);
        } else this.numArrTo.push(0);
      });
    },

    // 选择规格
    onchangeGoodsStandardIdTo(val) {
      let data = this.productSpecificationsArr.filter((item) => {
        return item.id === val
      })
      this.editPrice = data[0].abate_price
      this.dataTo.goods_standard_name = data[0].standard_name
      this.abatePriceTo = data[0].abate_price
      this.dataTo.goods_list = [{
        goods_id: '',
        goods_name: '',
        goods_price: '',
        is_prepaid: '',
      }]
      this.$_register.get('api/recruit/goods-manage/get-book-goods', {params: {goods_standard_id: val}}).then(res => {
        if (res.data.data.length === 0) {
          this.$message.warning('该规格下没有预订订单')
          return
        }
        res.data.data.forEach(item => {
          this.dataTo.goods_list = [{
            goods_id: item.id,
            goods_name: item.goods_name,
            goods_price: item.goods_price,
            is_prepaid: 1,
          }]
          this.numArrTo[0] = item.goods_price
          this.$forceUpdate()
        })
      })
    },

    // 编辑预订商品选择
    onGoods_standard_idTo(index) {
      let data = this.bookingGoodsArr.filter((item) => {
        return item.id === this.dataTo.goods_list[index].goods_id
      })
      this.dataTo.goods_list[index].goods_price = data[0].goods_price
    },

    // 编辑添加组合商品
    onAddGoodsStandardIdTo() {
      this.dataTo.goods_list.push({
        goods_id: '',
        goods_name: '',
        goods_price: '',
        is_prepaid: 0,
      })
    },

    // 删除商品组合
    onDeleteGoodsStandardIdTo(index) {
      this.dataTo.goods_list.splice(index, 1)
    },

    // 确定按钮
    onSureTo() {
      if (this.dataTo.goods_standard_id) {
        this.ruleForm.pack_group_list[this.editIndex] = JSON.parse(JSON.stringify(this.dataTo))
        this.data = JSON.parse(JSON.stringify(this.ruleForm))
        this.numArr[this.editIndex - 1] = this.editPrice
        this.dialogVisibleTo = false
        let num = 0
        this.dataTo.goods_list.forEach(item => {
          if (item.goods_price) {
            num += Number(item.goods_price)
          } else num += 0
        })

        this.priceArr[this.editIndex] = num
      } else this.$message.warning('请先选择商品规格')
      this.$forceUpdate()
    },

    // 添加商品组合
    onAddGoodsStandardId() {
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list.push({
        goods_id: '',
        goods_name: '',
        goods_price: '',
        is_prepaid: '',
      });
    },

    // 删除商品组合
    onDeleteGoodsStandardId(index) {
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list.splice(index, 1);
    },

    // 确定按钮
    onSure() {
      if (this.ruleForm.pack_group_list[this.index].goods_standard_id) {
        this.data = JSON.parse(JSON.stringify(this.ruleForm));
        this.dialogVisible = false;
        let data = 0;
        this.data.pack_group_list[this.index].goods_list.forEach((item_, index_) => {
          data += Number(item_.goods_price);
        });
        this.priceArr.push(data);
        this.dialogVisible = false;
      } else this.$message.warning('请先选择商品规格');
    },

    // 预订商品选择
    onGoods_standard_id(index) {
      let data = this.bookingGoodsArr.filter((item) => {
        return item.id === this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[index].goods_id;
      });
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[index].goods_name = data[0].goods_name;
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[index].goods_price = data[0].goods_price;
      this.ruleForm.pack_group_list[this.ruleForm.pack_group_list.length - 1].goods_list[index].is_prepaid = 0;
    },

    // 添加组合商品
    onAdd() {
      if (this.ruleForm.pack_group_list.length === 30) {
        this.$message.warning('最多绑定30个规格')
        return
      }
      this.num = 0;
      this.dialogVisible = true;
      this.ruleForm.pack_group_list.push({
        goods_standard_id: '',
        goods_standard_name: '',
        is_online: 1,
        goods_list: [{
          goods_id: '',
          goods_name: '',
          goods_price: '',
          is_prepaid: '',
        }],
      });
      this.index++;
      this.numArr[this.index] = 0;
    },

    onTakeAway() {
      this.dialogVisible = false;
      this.index--;
      this.numArr.pop();
      this.ruleForm.pack_group_list.pop();
    },

    onUnder(val) {
      // let data = JSON.parse(JSON.stringify(this.data.pack_group_list));
      // let dataTo = JSON.parse(JSON.stringify(this.ruleForm.pack_group_list));
      // data.splice(val, 1);
      // dataTo.splice(val, 1);
      // this.numArr.splice(val, 1);
      // this.numArrTo.splice(val, 1);
      // this.priceArr.splice(val, 1);
      // this.ruleForm.pack_group_list = data;
      // this.data.pack_group_list = dataTo;
      // this.index--;
      this.$confirm(this.data.pack_group_list[val].is_online == 1 ? '是否下架该商品' : '是否上架该商品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.data.pack_group_list[val].is_online == 1) {
          this.data.pack_group_list[val].is_online = 0
          this.ruleForm.pack_group_list[val].is_online = 0
        } else {
          this.data.pack_group_list[val].is_online = 1
          this.ruleForm.pack_group_list[val].is_online = 1
        }
      }).catch(() => {
      });
      this.$forceUpdate();
    },

    onEdit(val) {
      this.dataTo = JSON.parse(JSON.stringify(this.ruleForm.pack_group_list[val]))
      this.editIndex = val
      this.dialogVisibleTo = true
      let data = this.productSpecificationsArr.filter((item) => {
        return item.id === this.dataTo.goods_standard_id
      })
      this.abatePriceTo = data[0].abate_price
    },
  },
// 计算属性 类似于 data 概念
  computed: {
    countRuleForm() {
      let data = JSON.parse(JSON.stringify(this.ruleForm.pack_group_list))
      if (data.length > 0) {
        if (data[data.length - 1].goods_list) {
          return data[data.length - 1].goods_list.every(item => {
            return item.goods_id !== ''
          })
        }
      }
      return false
    },
    countRuleFormTo() {
      let data = JSON.parse(JSON.stringify(this.dataTo))
      if (data.goods_list)
        return data.goods_list.every(item => {
          return item.goods_id !== ''
        })
      return false
    },
  },
// 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
    this.$_register.get('api/recruit/goods-manage/goods-standard-list?page_size=0').then(result => {
      this.productSpecificationsArr = result.data.data;
    });

    this.$_register.get('api/recruit/goods-manage/goods-list?page_size=0').then(result => {
      this.bookingGoodsArr = result.data.data;
    });
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-dialog {
	width: 800rem !important;
}

@media only screen and (min-width: 1140rem) {
	.independentStyle {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.independentStyle {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
